import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRequestItems } from './hook/useRequestItems';
import { useSelector } from 'react-redux';
import { requestsListLoadingSelector } from '../../../../../redux/selector/requestSelector';

import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    FileTextOutlined,
    LoadingOutlined,
    TruckOutlined,
} from '@ant-design/icons';
import { Menu, Skeleton } from 'antd';

import { ChatRequest } from '../../../../../entity/type/ChatRequest';
import { RequestService } from '../../../../../util/RequestService';
import { User } from '../../../../../entity/type/User';
import { RequestStatus } from '../../../../../entity/enums/RequestStatus';
import { PATHS } from '../../../../../routing/type/Path';
import { PaginatedResponse } from '../../../../../api/type/PaginatedResponse';

import './left_menu.css';


type LeftMenuRequestsProps = {
    requestsData: PaginatedResponse<ChatRequest>;
    userData: User;
};

const LeftMenu: FC<LeftMenuRequestsProps> = ({ requestsData, userData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [openKeys, setOpenKeys] = useState(['active_requests']);
    const [current, setCurrent] = useState<string>('');
    const requestListLoading: boolean = useSelector(
        requestsListLoadingSelector
    );

    const handleItemClick = useCallback(
        (request: ChatRequest, key: string): void => {
            setCurrent(key);
            RequestService.getRequestById(request.id);
            navigate(`${PATHS.PART_SEARCH}/${request.id}`);
        },
        [navigate]
    );

    const { getRequestItems } = useRequestItems(
        requestsData,
        userData,
        handleItemClick
    );

    const items = useMemo(
        () => [
            {
                key: 'active_requests',
                label: t('Active Requests'),
                icon: <FileTextOutlined />,
                children: [
                    {
                        key: 'requests_in_progress',
                        label: t('In Progress'),
                        icon: <LoadingOutlined />,
                        children: getRequestItems([
                            RequestStatus.CREATED,
                            RequestStatus.IN_PROGRESS,
                        ]),
                        type: 'group',
                    },
                    {
                        key: 'requests_with_offer',
                        label: t('Offer Created'),
                        icon: <LoadingOutlined />,
                        children: getRequestItems([
                            RequestStatus.OFFER_CREATED,
                        ]),
                        type: 'group',
                    },
                ],
            },
            {
                key: 'delivery_requests',
                label: t('Requests in delivery'),
                icon: <TruckOutlined />,
                children: [
                    {
                        key: 'delivery_requests_group',
                        label: null,
                        icon: <LoadingOutlined />,
                        children: getRequestItems([
                            RequestStatus.IN_DELIVERY_BY_COURIER,
                            RequestStatus.IN_DELIVERY_BY_AGENT,
                        ]),
                        type: 'group',
                    },
                ],
            },
            {
                key: 'closed_requests',
                label: t('Closed Requests'),
                icon: <CheckCircleOutlined />,
                children: [
                    {
                        key: 'closed_requests_group',
                        label: null,
                        icon: <CloseCircleOutlined />,
                        children: getRequestItems([RequestStatus.CLOSED]),
                        type: 'group',
                    },
                ],
            },
        ],
        [getRequestItems, t]
    );

    const onOpenChange = (keys: string[]): void => {
        setOpenKeys(keys);
    };

    if (requestListLoading) {
        return (
            <Skeleton
                active
                paragraph={{ rows: 10 }}
                className='iauto-request-menu-loader'
            />
        );
    }

    return (
        <div className='iauto-requests-menu'>
            <Menu
                mode='inline'
                openKeys={openKeys}
                selectedKeys={[current]}
                onOpenChange={onOpenChange}
                items={items}
                style={{
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: 8,
                }}
            />
        </div>
    );
};

export default LeftMenu;