import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {DataType} from './AvailableRequests';
import {Button, List} from 'antd';
import {CheckCircleOutlined, EyeOutlined} from "@ant-design/icons";
import {formatPhoneNumber} from "../../../../../../util/phone/PhoneUtility";


interface AvailableRequestsItemProps {
    listItemData: DataType
    onRequestPreviewButtonClick: (requestId: string) => void
    onRequestReserveButtonClick: (requestId: string) => void
}

const AvailableRequestsItem: FC<AvailableRequestsItemProps> = (
    {
        listItemData,
        onRequestPreviewButtonClick,
        onRequestReserveButtonClick
    }: AvailableRequestsItemProps) => {

    const {t} = useTranslation();
    const listItemTitle: string = `${t('User')}: ${listItemData.firstName} ${listItemData.lastName} `;
    const requestDescription: string = `${t('Phone Number')}: ${formatPhoneNumber(listItemData.phone)}`;
    const listItemDescription: string = `${requestDescription}`;
    const listItemActionsButtons: JSX.Element[] = [
        <Button
            type="default"
            icon={<EyeOutlined/>}
            onClick={() => onRequestPreviewButtonClick(listItemData.requestId)}
            size="middle"
        >
            {t('Preview')}
        </Button>,
        <Button
            type="primary"
            icon={<CheckCircleOutlined/>}
            onClick={() => onRequestReserveButtonClick(listItemData.requestId)}
            size="middle"
        >
            {t('Get to work')}
        </Button>,
    ];

    return (
        <List.Item className="iauto-avail-requests-item" actions={listItemActionsButtons}>
            <List.Item.Meta title={listItemTitle} description={listItemDescription}/>
        </List.Item>
    );
};

export default AvailableRequestsItem;