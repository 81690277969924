import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useHasPermission from '../../../../security/hook/useHasPermission';
import { useTranslation } from 'react-i18next';
import { useChatHeaderActionsMenu } from './hook/useChatHeaderActionsMenu';
import { useRequestTitle } from './hook/useRequestTitle';

import { Button, Input } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import ChatHeaderDropdownButton from './component/ChatHeaderDropdownButton';

import { adminRoles } from '../../../../security/hook/roles';
import { ChatRequest } from '../../../../entity/type/ChatRequest';
import { RequestStatus } from '../../../../entity/enums/RequestStatus';

import './header.css';


type ChatHeaderProps = {
    activeRequest: ChatRequest;
};

const ChatHeader: FC<ChatHeaderProps> = ({ activeRequest }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const hasAdminPermission = useHasPermission(adminRoles);
    const { requestTitle, handleRequestNameChange, saveNewRequestName } =
        useRequestTitle();

    const [editBlockVisible, setEditBlockVisible] = useState(false);
    const isPreviewMode = !!activeRequest.isPreview;

    const editButtonIsVisible = () => {
        return activeRequest?.status !== RequestStatus.CLOSED && !isPreviewMode;
    };
    const createOfferDropdownIsVisible = () => {
        return hasAdminPermission && !isPreviewMode;
    };

    const { dropdownItems, handleMenuClick } = useChatHeaderActionsMenu(
        activeRequest,
        dispatch
    );

    const [dynamicDropdownItems, setDynamicDropdownItems] = useState(dropdownItems);

    useEffect(() => {
        setDynamicDropdownItems(dropdownItems);
    }, [activeRequest.status, dropdownItems]);

    return (
        <div className='iauto-request-chat-header'>
            <ChatHeaderDropdownButton
                items={dynamicDropdownItems}
                onClick={(key) => handleMenuClick(key)}
                visible={createOfferDropdownIsVisible()}
                label={t('Actions')}
            />
            {editBlockVisible ? (
                <>
                    <Input
                        type='text'
                        value={requestTitle}
                        onChange={handleRequestNameChange}
                        style={{ width: 300 }}
                    />
                    <Button
                        icon={<CheckOutlined />}
                        onClick={() => {
                            saveNewRequestName();
                            setEditBlockVisible(false);
                        }}
                    />
                </>
            ) : (
                <>
                    <div>{requestTitle}</div>
                    {editButtonIsVisible() && (
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => setEditBlockVisible(true)}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default memo(ChatHeader);
