import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userDataSelector } from '../../../../../redux/selector/userSelector';

import { UserRole } from '../../../../../entity/enums/UserRole';
import { AuthenticatedUser, User } from '../../../../../entity/type/User';
import { Offer } from '../../../../../entity/type/Offer';
import { OfferStatus } from '../../../../../entity/enums/OfferStatus';
import { Badge, Button, Empty, Grid, Image, Space, Table } from 'antd';
import OfferCards from './OfferCards';

import { getFormattedDate } from '../../../../../util/Utilities';
import { OfferService } from '../../../../../util/OfferService';
import { RequestService } from '../../../../../util/RequestService';
import { OfferTableHeaderName } from './offerTableHeaderNames';

import './offer.css';

type OfferMessageContentProps = {
    offerData: Offer;
};

const OfferMessageContent: FC<OfferMessageContentProps> = ({ offerData }) => {
    const authenticatedUser: AuthenticatedUser = useSelector(userDataSelector);
    const screens = Grid.useBreakpoint();
    const currentUser: User = authenticatedUser?.user;
    const userRole: string = currentUser.role;
    const { t } = useTranslation();

    const offerTableHeaderNames: Array<OfferTableHeaderName> = [
        {
            title: t('Description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('Brand'),
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: t('Price'),
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: t('Quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: t('Delivery Time'),
            dataIndex: 'deliveryTime',
            key: 'deliveryTime',
        },
        {
            title: t('Photo'),
            dataIndex: 'images',
            key: 'images',
            // @ts-ignore
            render: (_: any, record: any) => {
                const firstImage = record.images?.[0]?.image || '';

                return firstImage ? (
                    // @ts-ignore
                    <Image
                        width={80}
                        src={firstImage}
                        alt='Product Image'
                        fallback='https://via.placeholder.com/50'
                    />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                );
            },
        },
    ];

    const getWrapperStyle = (): string => {
        switch (offerData?.status) {
            case OfferStatus.CANCELLED_BY_CLIENT:
            case OfferStatus.CANCELLED_BY_OPERATOR:
                return 'iauto-offer-message-wrapper-refused';
            case OfferStatus.ACCEPTED_BY_CLIENT:
            case OfferStatus.ACCEPTED_BY_OPERATOR:
                return 'iauto-offer-message-wrapper-accepted';
            default:
                return 'iauto-offer-message-wrapper';
        }
    };

    const getStatusBadge = (): JSX.Element => {
        switch (offerData?.status) {
            case OfferStatus.CANCELLED_BY_CLIENT:
            case OfferStatus.CANCELLED_BY_OPERATOR:
            case OfferStatus.CANCELLED_BY_OPERATOR_SUPERVISOR:
                return <Badge color='red' text={t('Refused')} />;
            case OfferStatus.ACCEPTED_BY_CLIENT:
            case OfferStatus.ACCEPTED_BY_OPERATOR:
            case OfferStatus.ACCEPTED_BY_OPERATOR_SUPERVISOR:
                return <Badge color='green' text={t('Accepted')} />;
            case OfferStatus.CREATED:
                return <Badge color='blue' text={t('Created')} />;
            default:
                return <Badge color='grey' text={t('Unknown')} />;
        }
    };

    const wrapperStyle: string = getWrapperStyle();
    const offerTitle: string = `${t('Offer from')} ${getFormattedDate(new Date().toString())}`;
    const offerTableRowsData = offerData?.offerPositions.map(
        (position: any, index: number) => {
            let tableRow: any = { key: index };

            offerTableHeaderNames.forEach((headerName: any) => {
                const colName = headerName.key;

                if (colName === 'deliveryTime') {
                    tableRow[colName] =
                        position[colName][0] + ' : ' + position[colName][1];
                } else {
                    tableRow[colName] = position[colName];
                }
            });

            return tableRow;
        }
    );

    const onAcceptOfferButtonClick = async () => {
        const newStatus: OfferStatus =
            userRole === UserRole.ROLE_ADMIN ||
            userRole === UserRole.ROLE_OPERATOR
                ? OfferStatus.ACCEPTED_BY_OPERATOR
                : OfferStatus.ACCEPTED_BY_CLIENT;

        await OfferService.updateOfferStatus(offerData, newStatus);
        await RequestService.getRequestById(offerData.requestId);
    };

    const onRefuseOfferButtonClick = async () => {
        const newStatus: OfferStatus =
            userRole === UserRole.ROLE_ADMIN ||
            userRole === UserRole.ROLE_OPERATOR
                ? OfferStatus.CANCELLED_BY_OPERATOR
                : OfferStatus.CANCELLED_BY_CLIENT;

        await OfferService.updateOfferStatus(offerData, newStatus);
        await RequestService.getRequestById(offerData.requestId);
    };

    const offerTableHeader: JSX.Element = (
        <Space className='iauto-offer-table-header' align='center'>
            <div className='offer-title'>{getStatusBadge()}</div>
            {/*<div>*/}
            {/*    {offerData && !(offerData?.status === OfferStatus.REFUSED) && (*/}
            {/*        <PDFDocumentGenerator*/}
            {/*            fileName="offer"*/}
            {/*            linkName="Скачать оферту PDF"*/}
            {/*            pdfDocument={<OfferPDFDocument offerTitle={offerTitle}*/}
            {/*                                           offerTableRowsData={offerTableRowsData}*/}
            {/*            />}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*</div>*/}
            <div className='offer-title'>{offerTitle}</div>
        </Space>
    );

    const offerTableFooter: JSX.Element | boolean = offerData?.status ===
        OfferStatus.CREATED && (
        <Space className='offer-footer-actions'>
            <Button type='primary' onClick={onAcceptOfferButtonClick}>
                {t('Accept')}
            </Button>
            <Button type='default' danger onClick={onRefuseOfferButtonClick}>
                {t('Cancel')}
            </Button>
        </Space>
    );

    return (
        <div className={wrapperStyle}>
            <div className='iauto-offer-content'>
                {screens.xxl ? (
                    <Table
                        className='iauto-message-offer'
                        tableLayout='fixed'
                        size='small'
                        bordered={true}
                        title={() => offerTableHeader}
                        columns={offerTableHeaderNames}
                        dataSource={offerTableRowsData}
                        footer={() => offerTableFooter}
                        pagination={false}
                    />
                ) : (
                    <>
                        <OfferCards offerData={offerData} />
                        <Space className='offer-footer-actions'>
                            <Button
                                type='primary'
                                onClick={onAcceptOfferButtonClick}>
                                {t('Accept')}
                            </Button>
                            <Button
                                type='default'
                                danger
                                onClick={onRefuseOfferButtonClick}>
                                {t('Cancel')}
                            </Button>
                        </Space>
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(OfferMessageContent);
