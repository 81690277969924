import React, { FC } from 'react';
import { Card, Row, Col, Image, Empty } from 'antd';
import { Offer } from '../../../../entity/type/Offer';

interface OfferCardsProps {
    offerData: Offer;
}


const OfferCards: FC<OfferCardsProps> = ({ offerData }) => (
    <div className='iauto-cards-wrapper'>
        {offerData.offerPositions.map((position, index) => (
            <Card
                key={index}
                title={position.productName || 'Product'}
                className='iauto-card'>
                <Row gutter={[8, 8]}>
                    {position.images?.[0]?.image ? (
                        <Col span={24}>
                            <Image
                                width={100}
                                src={position.images[0].image}
                                alt='Product Image'
                                fallback='https://via.placeholder.com/80'
                            />
                        </Col>
                    ) : (
                        <Col span={24}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </Col>
                    )}
                    <Col span={24}>
                        <strong>Description:</strong>{' '}
                        {position.description || 'N/A'}
                    </Col>
                    <Col span={24}>
                        <strong>Brand:</strong> {position.brand || 'N/A'}
                    </Col>
                    <Col span={24}>
                        <strong>Price:</strong> {position.price || 'N/A'}
                    </Col>
                    <Col span={24}>
                        <strong>Quantity:</strong> {position.quantity || 'N/A'}
                    </Col>
                    <Col span={24}>
                        <strong>Delivery Time:</strong>{' '}
                        {position.deliveryTime?.join(' - ') || 'N/A'}
                    </Col>
                </Row>
            </Card>
        ))}
    </div>
);

export default OfferCards;
