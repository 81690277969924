import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import './dropdown.css';

type DropdownButtonProps = {
    items: MenuProps['items'];
    onClick: (key: string) => void;
    visible: boolean;
    label: string;
};

const ChatHeaderDropdownButton: FC<DropdownButtonProps> = ({
    items,
    onClick,
    visible,
    label,
}) => {
    if (!visible) return null;

    return (
        <Dropdown
            menu={{
                items,
                onClick: ({ key }) => onClick(key),
            }}
            trigger={['hover']}>
            <Button type='primary' size='middle'>
                <Space>
                    {label}
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
};

export default ChatHeaderDropdownButton;
