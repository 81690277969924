import { useParams } from 'react-router-dom';
import { AppDispatch } from '../../../../redux/type/AppDispatch';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { RequestService } from '../../../../util/RequestService';
import { setActiveRequestAC } from '../../../../redux/action/request';


const usePromoFetchDetailedRequest = (): string | undefined => {
    const {requestId} = useParams<{ requestId: string }>();
    const dispatch:AppDispatch = useDispatch();

    useEffect((): void => {
        if (requestId) {
            RequestService.getPromoRequestById(requestId);
        } else {
            dispatch(setActiveRequestAC());
        }
    }, [requestId, dispatch]);

    return requestId;
};

export default usePromoFetchDetailedRequest;