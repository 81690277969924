import { useState, useCallback, useEffect } from 'react';
import useHasPermission from '../../../../../security/hook/useHasPermission';
import { useSelector } from 'react-redux';
import { userDataSelector } from '../../../../../redux/selector/userSelector';
import { activeRequestSelector } from '../../../../../redux/selector/requestSelector';

import { ChatRequest } from '../../../../../entity/type/ChatRequest';
import { AuthenticatedUser, User } from '../../../../../entity/type/User';
import { RequestService } from '../../../../../util/RequestService';
import { adminRoles } from '../../../../../security/hook/roles';


export const useRequestTitle = () => {
    const hasAdminPermission = useHasPermission(adminRoles);
    const authUser: AuthenticatedUser = useSelector(userDataSelector);
    const userData: User = authUser?.user;
    const activeRequest: ChatRequest = useSelector(activeRequestSelector) ;

    const [requestTitle, setRequestTitle] = useState(
        hasAdminPermission ? activeRequest?.titleByOperator : activeRequest?.titleByClient
    );

    useEffect(() => {
        setRequestTitle(
            hasAdminPermission ? activeRequest?.titleByOperator : activeRequest?.titleByClient
        );
    }, [activeRequest, hasAdminPermission]);

    const handleRequestNameChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setRequestTitle(e.target.value);
        },
        []
    );

    const saveNewRequestName = useCallback(async () => {
        const requestParams = { userId: userData.id };
        const updateRequestParams = hasAdminPermission
            ? { id: activeRequest.id,  titleByOperator: requestTitle }
            : { id: activeRequest.id,  titleByClient: requestTitle };

        try {
            await RequestService.updateRequestAndRefreshActiveRequests(updateRequestParams, requestParams);
        } catch (error) {
            console.error('Error updating request title:', error);
        }
    }, [activeRequest.id, hasAdminPermission, requestTitle, userData.id]);

    return { requestTitle, handleRequestNameChange, saveNewRequestName };
};
