import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authCodeInputVisibleSelector } from '../../../redux/selector/serviceParametersSelector';
import { authLoading, userDataSelector } from '../../../redux/selector/userSelector';
import { setAuthCodeInputVisibleAC } from '../../../redux/action/serviceParameters';
import { authUserAC } from '../../../redux/action/user';
import { claimAnonymousRequestAC } from '../../../redux/action/request';
import { AppDispatch } from '../../../redux/type/AppDispatch';
import { usePhoneValidation } from '../../../util/phone/usePhoneValidation';

import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';

import { makeRandomKey } from '../../../util/Utilities';

import { PATHS } from '../../../routing/type/Path';
import { countryPhoneCodes } from './type/countryPhoneCodes';
import {
    getPhoneValidationRules,
    smsCodeValidationRules,
} from './type/validationRules';
import LocalStorageService from '../../../security/LocalStorageService';

import './auth.css';


const { Option } = Select;

const LoginPage: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();

    const userData = useSelector(userDataSelector);
    const authIsLoading = useSelector(authLoading);
    const showCodeInput = useSelector(authCodeInputVisibleSelector);

    const [verificationCode, setVerificationCode] = useState<string>('');
    const [selectedPrefix, setSelectedPrefix] = useState<string>('+373');
    const { phoneNumber, handlePhoneNumberChange } = usePhoneValidation(selectedPrefix);

    const [requestId, setRequestId] = useState<string | null>(null);

    useEffect(() => {
        const fetchAnonymousRequestId = async () => {
            const routerRequestId = (location.state as { requestId?: string })?.requestId;
            const storedRequestId = await LocalStorageService.getFromLocalStore('promoRequestId');
            setRequestId(routerRequestId || (storedRequestId as string | null));
        };

        fetchAnonymousRequestId();
    }, [location.state]);

    useEffect(() => {
        if (userData?.user?.id) {
            if (requestId) {
                checkAndClaimAnonymousRequest(requestId, userData.user.id);
            } else {
                navigate(PATHS.ROOT_PATH);
            }
        }
    }, [userData, requestId, navigate]);

    const checkAndClaimAnonymousRequest = async (requestId: string, userId: string): Promise<void> => {
        try {
            await dispatch(claimAnonymousRequestAC(requestId, userId));
            await LocalStorageService.saveToLocalStore('promoRequestId', '');
            navigate(PATHS.PART_SEARCH_WITH_ID.replace(':requestId', requestId));
        } catch (error) {
            console.error('Failed to claim request:', error);
        }
    };

    const handlePhoneSubmit = (): void => {
        dispatch(authUserAC(`${selectedPrefix}${phoneNumber}`));
    };

    const handleCodeSubmit = async (): Promise<void> => {
        try {
            await dispatch(authUserAC(`${selectedPrefix}${phoneNumber}`, verificationCode));
            dispatch(setAuthCodeInputVisibleAC(false));
        } catch (error) {
            console.error('Error during login or claim:', error);
            dispatch(setAuthCodeInputVisibleAC(false));
        }
    };

    const countryCodeOptions = useMemo(
        () =>
            countryPhoneCodes?.map((code) => (
                <Option key={makeRandomKey()} value={code?.value}>
                    {code?.value}
                </Option>
            )),
        []
    );

    return (
        <Row>
            <Col xs={1} sm={3} md={5} lg={7} xl={9} />
            <Col xs={22} sm={18} md={14} lg={10} xl={6}>
                <Divider orientation='left'>{t('Enter')}</Divider>
                {!showCodeInput ? (
                    <Form onFinish={handlePhoneSubmit}>
                        <Form.Item
                            name='Phone number'
                            rules={getPhoneValidationRules(selectedPrefix)}
                        >
                            <Input
                                addonBefore={
                                    <Select
                                        defaultValue={selectedPrefix}
                                        value={selectedPrefix}
                                        onChange={setSelectedPrefix}
                                    >
                                        {countryCodeOptions}
                                    </Select>
                                }
                                disabled={authIsLoading}
                                value={phoneNumber}
                                onChange={(e) => handlePhoneNumberChange(e.target.value)}
                                placeholder={t('Enter phone number')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                className='iauto-auth-btn'
                                htmlType='submit'
                                type='primary'
                                loading={authIsLoading}
                            >
                                {t('Enter')}
                            </Button>
                        </Form.Item>
                    </Form>
                ) : (
                    <Form onFinish={handleCodeSubmit}>
                        <Form.Item
                            name='SMS code'
                            rules={smsCodeValidationRules}
                        >
                            <Input
                                disabled={authIsLoading}
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                placeholder={t('Enter verification code')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                className='iauto-auth-btn'
                                htmlType='submit'
                                type='primary'
                                loading={authIsLoading}
                            >
                                {t('Verify')}
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Col>
            <Col xs={1} sm={3} md={5} lg={7} xl={9} />
        </Row>
    );
};

export default LoginPage;
