import { RequestActions } from '../action/request';
import { ChatRequest } from '../../entity/type/ChatRequest';
import { PaginatedResponse } from '../../api/type/PaginatedResponse';


export type RequestInitialStateType = {
    requests: PaginatedResponse<ChatRequest> | {};
    notStartedRequests: PaginatedResponse<ChatRequest> | {};
    activeRequest: any;
    detailedRequestsLoading: boolean;
    requestsListLoading: boolean,
    updateRequestLoading: boolean,
    claimRequestLoading: boolean
};

const initialState: RequestInitialStateType = {
    requests: {},
    notStartedRequests: {},
    activeRequest: null,
    detailedRequestsLoading: false,
    requestsListLoading: false,
    updateRequestLoading: false,
    claimRequestLoading: false
};

const request = (
    state = initialState,
    action: any
): RequestInitialStateType => {
    switch (action.type) {
        case RequestActions.GET_DETAILED_REQUEST_LOADING: {
            return {
                ...state,
                detailedRequestsLoading: action.payload,
            };
        }
        case RequestActions.GET_REQUESTS_LOADING: {
            return {
                ...state,
                requestsListLoading: action.payload,
            };
        }
        case RequestActions.UPDATE_REQUEST_LOADING: {
            return {
                ...state,
                updateRequestLoading: action.payload,
            };
        }
        case RequestActions.CLAIM_REQUEST_LOADING: {
            return {
                ...state,
                claimRequestLoading: action.payload,
            };
        }

        case RequestActions.GET_REQUESTS: {
            return {
                ...state,
                requests: {
                    items: action.payload.content,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    pageSize: action.payload.pageSize,
                    sort: action.payload.sort || [],
                },
            };
        }
        case RequestActions.GET_REQUEST: {
            return {
                ...state,
                requests: action.payload,
            };
        }
        case RequestActions.GET_NOT_STARTED_REQUESTS: {
            return {
                ...state,
                notStartedRequests: {
                    items: action.payload.content,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    pageSize: action.payload.pageSize,
                    sort: action.payload.sort || [],
                },
            };
        }
        case RequestActions.CREATE_REQUEST: {
            return {
                ...state,
            };
        }
        case RequestActions.UPDATE_REQUEST: {
            return {
                ...state,
            };
        }
        case RequestActions.GET_DETAILED_REQUEST: {
            const paginatedMessages = action.payload?.messages;

            const messages = paginatedMessages
                ? {
                      items: paginatedMessages?.content || [],
                      totalItems: paginatedMessages?.totalElements || 0,
                      totalPages: paginatedMessages?.totalPages || 0,
                      currentPage: paginatedMessages?.pageable?.pageNumber || 0,
                      pageSize: paginatedMessages?.pageable?.pageSize || 0,
                      sort: paginatedMessages?.pageable?.sort || [],
                  }
                : {
                      items: [],
                      totalItems: 0,
                      totalPages: 0,
                      currentPage: 0,
                      pageSize: 0,
                      sort: [],
                  };

            return {
                ...state,
                activeRequest: {
                    ...state.activeRequest,
                    ...action.payload,
                    messages: messages,
                    isPreview: false,
                },
            };
        }

        case RequestActions.SET_ACTIVE_REQUEST: {
            const detailedRequest = action.payload;
            if (!detailedRequest) {
                return {
                    ...state,
                };
            }
            const messages = detailedRequest.messages || {
                items: [],
                totalItems: 0,
                totalPages: 0,
                currentPage: 0,
                pageSize: 0,
                sort: [],
            };

            return {
                ...state,
                activeRequest: {
                    ...detailedRequest,
                    messages: {
                        ...messages,
                        items: messages.items || [],
                        totalItems: messages.totalItems || 0,
                        totalPages: messages.totalPages || 0,
                        currentPage: messages.currentPage || 0,
                        pageSize: messages.pageSize || 0,
                        sort: messages.sort || [],
                    },
                    isPreview: false,
                },
            };
        }
        case RequestActions.SET_ACTIVE_REQUEST_IN_PREVIEW_MODE: {
            return {
                ...state,
                activeRequest: {
                    ...state.activeRequest,
                    isPreview: action.payload,
                },
            };
        }
        case RequestActions.UPDATE_REQUEST_ON_CHAT_MESSAGE_RECEIVE: {
            const receivedMessage = action.payload;

            if (!state.activeRequest) return state;

            const updatedMessages = {
                ...state.activeRequest.messages,
                items: [...state.activeRequest.messages.items, receivedMessage],
            };

            return {
                ...state,
                activeRequest: {
                    ...state.activeRequest,
                    messages: updatedMessages,
                },
            };
        }
        default:
            return state;
    }
};

export default request;
