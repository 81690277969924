import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { displayOfferModalAC } from '../../../../../redux/action/serviceParameters';
import { useSelector } from 'react-redux';
import { userDataSelector } from '../../../../../redux/selector/userSelector';

import {
    CarFilled,
    CloseCircleFilled,
    FileAddOutlined,
    RedoOutlined,
    TruckFilled,
} from '@ant-design/icons';
import { MenuProps } from 'antd';
import { ChatHeaderActions } from '../type/ChatHeaderActions';
import { ChatRequest } from '../../../../../entity/type/ChatRequest';
import { RequestService } from '../../../../../util/RequestService';
import { AuthenticatedUser } from '../../../../../entity/type/User';
import { RequestStatus } from '../../../../../entity/enums/RequestStatus';


export const useChatHeaderActionsMenu = (
    activeRequest: ChatRequest,
    dispatch: any
) => {
    const { t } = useTranslation();
    const userAuth: AuthenticatedUser = useSelector(userDataSelector);
    const user = userAuth?.user;

    const handleMenuClick = useCallback(
        async (key: string) => {
            try {
                switch (key) {
                    case ChatHeaderActions.CREATE_OFFER:
                        dispatch(displayOfferModalAC(true));
                        break;
                    case ChatHeaderActions.CLOSE_REQUEST:
                        await RequestService.updateRequestAndRefreshActiveRequests(
                            { id: activeRequest.id, status: RequestStatus.CLOSED },
                            { operatorId: user.id }
                        );
                        console.log('Request closed successfully');
                        break;
                    case ChatHeaderActions.REOPEN_REQUEST:
                        await RequestService.updateRequestAndRefreshActiveRequests(
                            { id: activeRequest.id, status: RequestStatus.IN_PROGRESS },
                            { operatorId: user.id }
                        );
                        console.log('Request reopened successfully');
                        break;
                    case ChatHeaderActions.IN_DELIVERY_BY_COURIER:
                        await RequestService.updateRequestAndRefreshActiveRequests(
                            { id: activeRequest.id, status: RequestStatus.IN_DELIVERY_BY_COURIER },
                            { operatorId: user.id }
                        );
                        console.log('Request set to In Delivery by Courier');
                        break;
                    case ChatHeaderActions.IN_DELIVERY_BY_AGENT:
                        await RequestService.updateRequestAndRefreshActiveRequests(
                            { id: activeRequest.id, status: RequestStatus.IN_DELIVERY_BY_AGENT },
                            { operatorId: user.id }
                        );
                        console.log('Request set to In Delivery by Agent');
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.error(`Error handling action (${key}):`, error);
            }
        },
        [activeRequest.id, dispatch, user.id]
    );

    const dropdownItems: MenuProps['items'] = useMemo(() => {
        switch (activeRequest.status) {
            case RequestStatus.CLOSED:
                return [
                    {
                        key: ChatHeaderActions.REOPEN_REQUEST,
                        label: t('Reopen request'),
                        icon: <RedoOutlined />,
                    },
                ];

            case RequestStatus.IN_PROGRESS:
                return [
                    {
                        key: ChatHeaderActions.CREATE_OFFER,
                        label: t('Create offer'),
                        icon: <FileAddOutlined />,
                    },
                    {
                        key: ChatHeaderActions.IN_DELIVERY_BY_COURIER,
                        label: t('In delivery by courier'),
                        icon: <CarFilled />,
                    },
                    {
                        key: ChatHeaderActions.IN_DELIVERY_BY_AGENT,
                        label: t('In delivery by agent'),
                        icon: <TruckFilled />,
                    },
                    {
                        key: ChatHeaderActions.CLOSE_REQUEST,
                        label: t('Close request'),
                        icon: <CloseCircleFilled />,
                    },
                ];

            case RequestStatus.IN_DELIVERY_BY_COURIER:
            case RequestStatus.IN_DELIVERY_BY_AGENT:
                return [
                    {
                        key: ChatHeaderActions.CLOSE_REQUEST,
                        label: t('Close request'),
                        icon: <CloseCircleFilled />,
                    },
                    {
                        key: ChatHeaderActions.REOPEN_REQUEST,
                        label: t('Reopen request'),
                        icon: <RedoOutlined />,
                    },
                ];

            case RequestStatus.OFFER_CREATED:
                return [
                    {
                        key: ChatHeaderActions.CREATE_OFFER,
                        label: t('Create offer'),
                        icon: <FileAddOutlined />,
                    },
                    {
                        key: ChatHeaderActions.IN_DELIVERY_BY_COURIER,
                        label: t('In delivery by courier'),
                        icon: <CarFilled />,
                    },
                    {
                        key: ChatHeaderActions.IN_DELIVERY_BY_AGENT,
                        label: t('In delivery by agent'),
                        icon: <TruckFilled />,
                    },
                    {
                        key: ChatHeaderActions.CLOSE_REQUEST,
                        label: t('Close request'),
                        icon: <CloseCircleFilled />,
                    },
                ];

            default:
                return [];
        }
    }, [activeRequest.status, t]);

    return { dropdownItems, handleMenuClick };
};
