export enum OfferStatus {
    CREATED = "CREATED",
    EXPIRED = "EXPIRED",
    CANCELLED_BY_OPERATOR = "CANCELLED_BY_OPERATOR",
    CANCELLED_BY_CLIENT = "CANCELLED_BY_CLIENT",
    ACCEPTED_BY_OPERATOR = "ACCEPTED_BY_OPERATOR",
    ACCEPTED_BY_CLIENT = "ACCEPTED_BY_CLIENT",
    ACCEPTED_BY_OPERATOR_SUPERVISOR = "ACCEPTED_BY_OPERATOR_SUPERVISOR",
    CANCELLED_BY_OPERATOR_SUPERVISOR = "CANCELLED_BY_OPERATOR_SUPERVISOR",
    ARCHIVED = "ARCHIVED"
}
