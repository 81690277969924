import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useSelector } from 'react-redux';
import {
    activeRequestSelector,
    requestLoadingSelector,
} from '../../../redux/selector/requestSelector';
import useFetchPromoDetailedRequest from '../cabinet/hook/useFetchPromoDetailedRequest';

import { Empty, Skeleton, Typography } from 'antd';
import { Offer } from '../../../entity/type/Offer';
import OfferTable from './component/OfferTable';
import OfferCards from './component/OfferCards';
import InfoBlock from './component/InfoBlock';
import { Message } from '../../../entity/type/Message';
import LocalStorageService from '../../../security/LocalStorageService';
import { PATHS } from '../../../routing/type/Path';
import { ChatRequest } from '../../../entity/type/ChatRequest';

import './promo_request.css';
import { RequestType } from '../../../entity/enums/RequestType';


const { Text, Title } = Typography;

const PromoRequestPage: FC = () => {
    const navigate = useNavigate();
    const screens = useBreakpoint();
    useFetchPromoDetailedRequest();
    const activeRequest: ChatRequest = useSelector(activeRequestSelector);
    const detailedRequestsLoading: boolean = useSelector(
        requestLoadingSelector
    );

    const offerData: Offer | undefined = activeRequest?.messages?.items?.find(
        (message: Message) => message.offer
    )?.offer;

    const handleEnterClick = async () => {
        if (activeRequest && activeRequest.type === RequestType.ANONYMOUS) {
            await LocalStorageService.saveToLocalStore(
                'promoRequestId',
                activeRequest.id
            );
            navigate(PATHS.AUTH_PATH, {
                state: { requestId: activeRequest.id },
            });
        }
    };

    const content = () => {
        if (detailedRequestsLoading) {
            return <Skeleton active paragraph={{ rows: 4 }} />;
        }

        if (offerData) {
            return screens.md ? (
                <OfferTable offerData={offerData} />
            ) : (
                <OfferCards offerData={offerData} />
            );
        }

        return <Text>No offer data available</Text>;
    };

    const requestIsAnonymous = (): boolean => {
        return (
            activeRequest &&
            activeRequest.type &&
            activeRequest.type === RequestType.ANONYMOUS
        );
    };

    const clientIsNotAssignedToRequest = () => {
        return !activeRequest || !activeRequest.client;
    };

    return (
        <div className='iauto-promo-request-container'>
            {requestIsAnonymous() ? (
                <>
                    <Title level={2} className='iauto-page-title'>
                        Offer Details
                    </Title>

                    {content()}

                    {!detailedRequestsLoading &&
                        clientIsNotAssignedToRequest() && (
                            <InfoBlock onEnterButtonClick={handleEnterClick} />
                        )}
                </>
            ) : (
                <Empty description='This request is expired, call admin for new promo-offer' />
            )}
        </div>
    );
};

export default PromoRequestPage;
