import React, { FC } from 'react';
import { Typography, Button } from 'antd';

const { Text } = Typography;

interface InfoBlockProps {
    onEnterButtonClick: () => void;
}

const InfoBlock: FC<InfoBlockProps> = ({ onEnterButtonClick }) => (
    <div className='iauto-info-block'>
        <Text>
            This is your offer. If you need to modify it, or get more
            information, please enter.
        </Text>
        <Button type='primary' onClick={onEnterButtonClick}>
            Enter
        </Button>
    </div>
);

export default InfoBlock;
