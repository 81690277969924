import React, { FC } from 'react';
import { Table, Image, Empty } from 'antd';
import { Offer } from '../../../../entity/type/Offer';
import { OfferPosition } from '../../../../entity/type/OfferPosition';

interface OfferTableProps {
    offerData: Offer;
}

const OfferTable: FC<OfferTableProps> = ({ offerData }) => {
    const columns = [
        { title: 'Description', dataIndex: 'description', key: 'description' },
        { title: 'Brand', dataIndex: 'brand', key: 'brand' },
        { title: 'Price', dataIndex: 'price', key: 'price' },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
        {
            title: 'Delivery Time',
            dataIndex: 'deliveryTime',
            key: 'deliveryTime',
            render: (value: string[]) => value?.join(' - ') || 'N/A',
        },
        {
            title: 'Photo',
            dataIndex: 'images',
            key: 'images',
            render: (_: any, record: OfferPosition) => {
                const firstImage = record.images?.[0]?.image || '';
                return firstImage ? (
                    <Image
                        width={80}
                        src={firstImage}
                        alt='Product Image'
                        fallback='https://via.placeholder.com/50'
                    />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                );
            },
        },
    ];

    const dataSource = offerData.offerPositions.map((position, index) => ({
        ...position,
        key: index,
    }));

    return (
        <div className='iauto-offer-table-wrapper'>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                bordered
            />
        </div>
    );
};

export default OfferTable;
