import {RootState} from '../type/RootStore';
import {ChatRequest} from "../../entity/type/ChatRequest";
import {PaginatedResponse} from "../../api/type/PaginatedResponse";


// @ts-ignore
interface RequestSelectorTypes extends RootState {
    requests: PaginatedResponse<ChatRequest>,
    notStartedRequests: PaginatedResponse<ChatRequest>,
    activeRequest: ChatRequest,
    detailedRequestsLoading: boolean,
    requestsListLoading: boolean,
    updateRequestLoading: boolean,
    claimRequestLoading: boolean
}

export const requestsDataSelector = (state: RequestSelectorTypes): any => state.request.requests;
export const activeRequestSelector = (state: RequestSelectorTypes): any => state.request.activeRequest;
export const notStartedRequestsSelector = (state: RequestSelectorTypes): any => state.request.notStartedRequests;

export const requestLoadingSelector = (state: RequestSelectorTypes): any => state.request.detailedRequestsLoading;
export const requestsListLoadingSelector = (state: RequestSelectorTypes): any => state.request.requestsListLoading;
export const updateRequestLoadingSelector = (state: RequestSelectorTypes): any => state.request.updateRequestLoading;
export const claimRequestLoadingSelector = (state: RequestSelectorTypes): any => state.request.claimRequestLoading;