//types
import { HEADERS } from '../config/constant/Headers';
//services
import Logger from '../util/Logger';


/**
 * Utility to operate with phone local storage
 */
class LocalStorageService {
    static UNIQUE_ID: string = 'uniqueId';

    /**
     * Used to save user data to local store
     * @param key
     * @param value
     */
    static async saveToLocalStore(key: string, value: string): Promise<any> {
        await localStorage.setItem(key, value);
    }

    /**
     * Used to get data from local store by key
     * @param key
     */
    static async getFromLocalStore(key: string): Promise<any> {
        return localStorage.getItem(key);
    }

    static getPersistedDataFromLocalStore(): object {
        // @ts-ignore
        return JSON.parse(localStorage.getItem('persist:root'));
    }

    /**
     * Routine method to prepare headers for unregistered user, who auths with uniques user id
     */
    //TODO move this method to separate HTTPService
    static async getHeadersForUnregisteredUser() {
        const headers: any = {
            'Accept': 'application/json'
        };
        const userUniqueId: string = await this.getFromLocalStore(LocalStorageService.UNIQUE_ID);

        if (process.env.NODE_ENV === 'development') {
            Logger.info('LocalStoreService :: preparing headers for user with id - ' + userUniqueId);
        }

        headers[HEADERS.I_AUTO_HEADER] = userUniqueId;

        return headers;
    }
}

export default LocalStorageService;