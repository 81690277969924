import dayjs from 'dayjs';

export const APP_DATE_FORMAT = 'DD-MM-YYYY';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Ensures dates are in the YYYY-MM-DD format.
 * @param dates Array of date strings in either DD-MM-YYYY or YYYY-MM-DD format
 * @returns Array of dates in YYYY-MM-DD format or an empty array if invalid
 */
export const ensureDatesInDefaultFormat = (dates: string[] | null | undefined): string[] => {
    if (!dates || dates.length !== 2) {
        return [];
    }

    const [startDate, endDate] = dates;

    const formattedStartDate = dayjs(startDate, DEFAULT_DATE_FORMAT, true).isValid()
        ? startDate
        : dayjs(startDate, APP_DATE_FORMAT, true).isValid()
            ? dayjs(startDate, APP_DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
            : null;

    const formattedEndDate = dayjs(endDate, DEFAULT_DATE_FORMAT, true).isValid()
        ? endDate
        : dayjs(endDate, APP_DATE_FORMAT, true).isValid()
            ? dayjs(endDate, APP_DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
            : null;

    return formattedStartDate && formattedEndDate
        ? [formattedStartDate, formattedEndDate]
        : [];
};
