export enum PATHS {
    ROOT_PATH = '/',
    VIN_DECODER_PATH = '/win_decoder',
    SEARCH_HISTORY_PATH = '/search_history',
    AUTH_PATH = '/auth',
    NOT_FOUND_PATH = '/*',

    PART_SEARCH = '/requests',
    PART_SEARCH_WITH_ID = '/requests/:requestId',
    PROMO_REQUEST_PATH = '/promo/requests/:requestId',

    MY_PROFILE = '/my_profile',
}